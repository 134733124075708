
.product-families-wrapper
    position: relative
    width: 100%
    padding: 12px 0 12px 0

    box-shadow: inset 0 -.03125rem 0 #d2d2d2

    .slide-container
        height: 84px

    .swiper-product-families
        height: 84px

        .swiper-slide
            width: 256px

            @media (min-width: 1440px)
                width: 264px

    .slide-arrow-container
        &.left
            // make sure customer can't see the line of image between box arrow and empty space
            left: -1px
            display: none

            border-right: 1px solid $color-dapp-gray-3

            // able to see only desktop (>1440)
            @media screen and (min-width: 768px)
                display: flex

    &.-is-stick-right-side
        padding: 12px 0 12px 25px
        max-width: 100%

        // for content show stick right side (max-width 1220 following .container class)
        @media (min-width: 1330px)
            padding: 12px 0 12px calc((100% - 1220px - 48px) / 2)
            max-width: calc(100% + ((100% - 1220px) / 2))

    .view-all-container
        display: flex
        align-items: center
        justify-content: flex-start

        font-size: 11px

        .link
            display: inline-flex
            align-items: center
            justify-content: center
            gap: 4px
            margin-top: 20px
            color: $color-bnn
            cursor pointer

            > ::v-deep.v-icon
                color: $color-bnn

.swiper-button-disabled.swiper-button-disabled
    visibility: hidden

.slide-arrow-container
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2.25rem;
    height: calc(100% - .125rem);
    display: flex;
    align-items: center;
    background: #fff;
    cursor: pointer;
    z-index 10

    &.left
        left: 0;
        border-right: 1px solid #d2d2d2;
    &.right
        right: 0;
        border-left: 1px solid #d2d2d2;

    .slide-stripe-arrow
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.25rem;
        height: 6.125rem;
        .v-icon
            width: 1rem;
            height: 1rem;
            color: #d0d0d0;
